
import { baseURL } from "@/api/rest";
import { logout } from "@/services/auth";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { consultarCep, ufs } from "@/utils/endereco";
import { estadoCivilOptions, tiposPessoa } from "@/utils/pessoas";

export default defineComponent({
  props: {
    patrocinador: Object,
  },
  data() {
    return {
      isWhats: false,
      isTelegram: false,
    };
  },
  setup() {
    const user = reactive({
      email: "",
      name: "",
      apelido: "",
      password: "",
      password_confirmation: "",
      telefone: "",
      tipo: "F",
      documento: null,
      rg_insc: null,
      data_nascimento: null,
      sexo: null,
      estado_civil: null,
      telefone_fixo: null,
      whatsapp: "",
      telegram: "",
      cep: "",
      logradouro: null,
      endereco_numero: null,
      complemento: null,
      bairro: null,
      cidade: null,
      uf: null,
      referencia: null,
      aceita_receber_info: false,
      aceita_contrato: false,
    });

    const ok = ref(false);
    const msg = ref<string>("");
    const msgKey = ref<number>(0);
    const loading = ref<boolean>(false);

    const router = useRouter();

    async function cadastrar() {
      if (loading.value) {
        return;
      }
      loading.value = true;
      try {
        const resp = await fetch(
          `${baseURL}/api/cadastro/${router.currentRoute.value.params.hash}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(user),
          }
        ).then((r) => r.json());
        if (resp.status === 422) {
          const first = Object.entries(resp.errors)[0] as [string, [string]];
          msgKey.value++;
          msg.value =
            first[0] === "email"
              ? "E-mail já cadastrado. Por favor utilize outro"
              : first[1][0];
          return;
        }
        if (!resp.id) {
          msgKey.value++;
          msg.value = resp.message || "Não foi possível efetuar o cadastro ";
          return;
        }
        ok.value = true;
      } catch (e) {
        msgKey.value++;
        msg.value = "Não foi possível efetuar o cadastro";
      } finally {
        loading.value = false;
      }
    }

    const loadingCep = ref(false);
    const encontrouCep = ref(false);

    return {
      estadoCivilOptions,
      ufs,
      tiposPessoa,
      user,
      cadastrar,
      msg,
      msgKey,
      loading,
      ok,
      async acessar() {
        await logout();
      },

      loadingCep,
      encontrouCep,
      async consultarCep() {
        encontrouCep.value = false;
        if (!user.cep) {
          return;
        }
        loadingCep.value = true;
        try {
          const resp = await consultarCep(user.cep);
          if (!resp.erro) {
            user.logradouro = resp.logradouro;
            user.complemento = resp.complemento;
            user.bairro = resp.bairro;
            user.cidade = resp.localidade;
            user.uf = resp.uf;
            encontrouCep.value = true;
          }
        } catch (e) {
          console.log("Falha ao buscar cep", user.cep);
        } finally {
          loadingCep.value = false;
        }
      },
    };
  },
  computed: {
    isPF(): boolean {
      return this.user.tipo !== "J";
    },
    labelDocumento(): string {
      return this.isPF ? "CPF" : "CNPJ";
    },
    labelRgInsc(): string {
      return this.isPF ? "RG" : "Insc. Estadual";
    },
  },
  watch: {
    "user.telefone"() {
      if (this.isWhats) {
        this.user.whatsapp = this.user.telefone;
      }
      if (this.isTelegram) {
        this.user.telegram = this.user.telefone;
      }
    },
    isWhats(v) {
      v && (this.user.whatsapp = this.user.telefone);
    },
    isTelegram(v) {
      v && (this.user.telegram = this.user.telefone);
    },
  },
});
