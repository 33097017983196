<template>
  <div class="public-container">
    <div class="p-d-flex p-flex-column p-ai-center p-jc-start p-jc-sm-center">
      <div class="p-text-center">
        <img alt="Logo" src="assets/images/logo1.png" style="height: 200px" />
      </div>
      <Formulario v-if="confirmou" :patrocinador="patrocinador" />
      <p-card v-else>
        <template #content v-if="loading">
          <div class="p-text-center">
            <p-progress-spinner style="width: 50px; height: 50px" />
          </div>
        </template>
        <template #content v-else-if="patrocinador">
          <div class="p-text-center">
            Antes de realizar o seu cadastro,
            <br />por favor confirme os dados do seu patrocinador:
          </div>
          <div class="p-mt-3 p-text-center">
            ID do patrocinador:
            <b>{{ patrocinador.code }}</b>
            <br />Nome:
            <b>{{ patrocinador.name }}</b>
            <span v-if="patrocinador.apelido">
              <br />Apelido:
              <b>{{ patrocinador.apelido }}</b>
            </span>
          </div>
          <div class="p-field-checkbox p-mt-4 p-jc-center">
            <p-checkbox id="confirm" v-model="checkPatrocinador" binary />
            <label for="confirm">Sim, o patrocinador está correto</label>
          </div>
          <div
            class="p-d-flex p-flex-row-reverse p-col-12"
            style="justify-content: end"
          >
            <p-button
              icon="pi pi-check"
              label="Continuar"
              @click="confirmou = true"
              :disabled="!checkPatrocinador"
            />
            <p-button
              class="p-button-secondary p-mr-2"
              label="Cancelar"
              @click="$router.push({ name: 'dashboard' })"
            />
          </div>
        </template>
        <template #content v-else>
          <div class="p-text-center">
            <p-inline-message severity="warn">
              Não foi possível localizar o patrocinador.
              <br />Verifique se o link está correto e tente novamente.
            </p-inline-message>
          </div>
        </template>
      </p-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Formulario from "./Formulario.vue";
import { http } from "@/api/rest";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Cadastro",
  components: {
    Formulario,
  },
  data() {
    return {
      checkPatrocinador: false,
    };
  },
  setup() {
    const router = useRouter();
    const confirmou = ref(false);
    const patrocinador = ref();
    const loading = ref(false);
    async function loadInfo() {
      loading.value = true;
      try {
        const result = (
          await http.get(
            `/api/cadastro/${router.currentRoute.value.params.hash}`
          )
        ).data;
        patrocinador.value = result;
      } finally {
        loading.value = false;
      }
    }
    onMounted(() => {
      if (["298bb8bc", "378bc4uw"].includes(router.currentRoute.value.params.hash as string)) {
        confirmou.value = true;
      } else {
        loadInfo();
      }
    });
    return {
      confirmou,
      loading,
      patrocinador,
    };
  },
});
</script>
