<template>
  <p-card v-if="patrocinador" style="background-color: #f9c851" class="p-mb-2">
    <template #content>
      ID do patrocinador:
      <b>{{ patrocinador.code }}</b>
      <br />
      Nome: <b>{{ patrocinador.name }}</b>
      <span v-if="patrocinador.apelido">
        <br />
        Apelido: <b>{{ patrocinador.apelido }}</b>
      </span>
    </template>
  </p-card>

  <p-card v-if="ok">
    <template #content>
      <p-message severity="success" :closable="false">
        Cadastro efetuado com sucesso
      </p-message>
      <div class="p-text-center">
        <p-button label="Acessar Sistema" @click="acessar" />
      </div>
    </template>
  </p-card>

  <form v-else method="POST" @submit.prevent="cadastrar" style="width: 100%">
    <p-card class="p-mb-6">
      <template #title>Cadastro</template>
      <template #content>
        <div class="p-fluid">
          <div class="p-field">
            <label>Tipo</label>
            <p-dropdown
              v-model="user.tipo"
              required
              :options="tiposPessoa"
              optionLabel="label"
              optionValue="value"
            />
          </div>
          <div class="p-field">
            <label>{{ isPF ? "Nome Completo" : "Razão Social" }}</label>
            <p-inputtext v-model="user.name" required />
          </div>
          <div class="p-field">
            <label>{{ isPF ? "Apelido" : "Nome Fantasia" }} (opcional)</label>
            <p-inputtext v-model="user.apelido" />
          </div>
          <div class="p-field">
            <label>{{ labelDocumento }}</label>
            <InputMask
              v-if="isPF"
              v-model="user.documento"
              required
              mask="999.999.999-99"
            />
            <InputMask
              v-else
              v-model="user.documento"
              required
              mask="99.999.999/9999-99"
            />
          </div>
          <div class="p-field">
            <label>{{ labelRgInsc }}</label>
            <p-inputtext v-model="user.rg_insc" required />
          </div>
          <div class="p-field" v-if="isPF">
            <label style="width: 100%">Data Nascimento</label>
            <x-input-date
              v-model="user.data_nascimento"
              required
              style="width: 100px"
            />
            <!-- <p-inputtext type="date" v-model="user.data_nascimento" required /> -->
          </div>
          <div class="p-field" v-if="isPF">
            <label>Sexo</label>
            <p-dropdown
              v-model="user.sexo"
              required
              :options="[
                { value: 'masculino', label: 'Masculino' },
                { value: 'feminino', label: 'Feminino' },
                { value: '-', label: 'Prefiro não informar' },
              ]"
              optionLabel="label"
              optionValue="value"
              :class="{ 'p-invalid': !user.sexo }"
            />
          </div>
          <div class="p-field" v-if="isPF">
            <label>Estado Civil</label>
            <p-dropdown
              v-model="user.estado_civil"
              required
              :options="estadoCivilOptions"
              optionLabel="label"
              optionValue="value"
              :class="{ 'p-invalid': !user.estado_civil }"
            />
          </div>
        </div>

        <div class="p-fluid">
          <div class="p-field">
            <label>Telefone Fixo (deixe em branco se não tiver)</label>
            <InputMask v-model="user.telefone_fixo" mask="(99) 9999-9999" />
          </div>
          <div class="p-field">
            <label>Celular</label>
            <InputMask
              v-model="user.telefone"
              required
              mask="(99) 99999-9999"
            />
            <div class="p-d-flex p-jc-between p-ai-center p-mt-1 p-mb-3">
              <div>
                <p-checkbox v-model="isWhats" binary class="p-mr-1" />
                <label>É meu whatsapp</label>
              </div>
              <div>
                <p-checkbox v-model="isTelegram" binary class="p-mr-1" />
                <label>É meu telegram</label>
              </div>
            </div>
          </div>
          <div class="p-field">
            <label>Whatsapp</label>
            <p-inputtext
              v-model="user.whatsapp"
              v-input-mask="['(99) 9999-9999', '(99) 99999-9999']"
              :disabled="isWhats"
            />
          </div>
          <div class="p-field">
            <label>Telegram</label>
            <p-inputtext
              v-model="user.telegram"
              v-input-mask="['(99) 9999-9999', '(99) 99999-9999']"
              :disabled="isTelegram"
            />
          </div>
        </div>

        <div class="p-fluid">
          <div class="p-field">
            <label style="display: block">
              CEP
              <i v-if="loadingCep" class="pi pi-spin pi-spinner"></i>
            </label>
            <InputMask
              v-model="user.cep"
              required
              mask="99999-999"
              @blur="consultarCep"
              style="width: 100px"
            />
          </div>
          <div class="p-field">
            <label>Logradouro (Rua,Avenida,Estrada,Rodovia,Etc)</label>
            <p-inputtext v-model="user.logradouro" required />
          </div>
          <div class="p-field">
            <label style="display: block">Número</label>
            <p-inputtext
              v-model="user.endereco_numero"
              required
              style="width: 100px"
            />
          </div>
          <div class="p-field">
            <label>Complemento</label>
            <p-inputtext v-model="user.complemento" />
          </div>
          <div class="p-field">
            <label>Referência</label>
            <p-inputtext v-model="user.referencia" />
          </div>
          <div class="p-field">
            <label>Bairro</label>
            <p-inputtext v-model="user.bairro" required />
          </div>
          <div class="p-field">
            <label>Cidade</label>
            <p-inputtext
              v-model="user.cidade"
              required
              :disabled="encontrouCep"
            />
          </div>
          <div class="p-field">
            <label>UF</label>
            <p-dropdown
              v-model="user.uf"
              required
              :options="ufs"
              optionLabel="nome"
              optionValue="sigla"
              :disabled="encontrouCep"
              :class="{ 'p-invalid': !user.uf }"
            />
          </div>
        </div>

        <div class="p-fluid">
          <div class="p-field">
            <label>E-mail</label>
            <p-inputtext
              type="email"
              v-model="user.email"
              required
              autocomplete="username"
            />
          </div>
          <div class="p-field">
            <label>Senha</label>
            <p-inputtext
              type="password"
              v-model="user.password"
              required
              autocomplete="new-password"
            />
          </div>
          <div class="p-field">
            <label>Confirme a senha</label>
            <p-inputtext
              type="password"
              v-model="user.password_confirmation"
              required
              autocomplete="new-password"
            />
          </div>

          <div class="p-field-checkbox p-mt-4 p-jc-center">
            <p-checkbox v-model="user.aceita_receber_info" binary />
            <label
              >Aceito receber informações e comunicações da empresa pelo
              whatsapp ou telegram</label
            >
          </div>
          <div class="p-field-checkbox p-mt-4 p-jc-center">
            <p-checkbox v-model="user.aceita_contrato" binary />
            <label
              >Declaro que lí e aceito o Contrato de Acordo e concordo com todos
              os seus termos (clique aqui para visualizar)</label
            >
          </div>
        </div>

        <div v-if="msg">
          <p-message severity="warn" :key="msgKey">
            {{ msg }}
          </p-message>
        </div>

        <div class="p-d-flex p-flex-row-reverse" style="justify-content: end">
          <p-button
            :icon="`pi ${loading ? 'pi-spin pi-spinner' : 'pi pi-check'}`"
            label="Finalizar"
            :disabled="loading || !user.aceita_contrato"
            type="submit"
          />
          <p-button
            class="p-button-secondary p-mr-2"
            label="Cancelar"
            @click="$router.push({ name: 'dashboard' })"
          />
        </div>
      </template>
    </p-card>
  </form>
</template>

<script lang="ts">
import { baseURL } from "@/api/rest";
import { logout } from "@/services/auth";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { consultarCep, ufs } from "@/utils/endereco";
import { estadoCivilOptions, tiposPessoa } from "@/utils/pessoas";

export default defineComponent({
  props: {
    patrocinador: Object,
  },
  data() {
    return {
      isWhats: false,
      isTelegram: false,
    };
  },
  setup() {
    const user = reactive({
      email: "",
      name: "",
      apelido: "",
      password: "",
      password_confirmation: "",
      telefone: "",
      tipo: "F",
      documento: null,
      rg_insc: null,
      data_nascimento: null,
      sexo: null,
      estado_civil: null,
      telefone_fixo: null,
      whatsapp: "",
      telegram: "",
      cep: "",
      logradouro: null,
      endereco_numero: null,
      complemento: null,
      bairro: null,
      cidade: null,
      uf: null,
      referencia: null,
      aceita_receber_info: false,
      aceita_contrato: false,
    });

    const ok = ref(false);
    const msg = ref<string>("");
    const msgKey = ref<number>(0);
    const loading = ref<boolean>(false);

    const router = useRouter();

    async function cadastrar() {
      if (loading.value) {
        return;
      }
      loading.value = true;
      try {
        const resp = await fetch(
          `${baseURL}/api/cadastro/${router.currentRoute.value.params.hash}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(user),
          }
        ).then((r) => r.json());
        if (resp.status === 422) {
          const first = Object.entries(resp.errors)[0] as [string, [string]];
          msgKey.value++;
          msg.value =
            first[0] === "email"
              ? "E-mail já cadastrado. Por favor utilize outro"
              : first[1][0];
          return;
        }
        if (!resp.id) {
          msgKey.value++;
          msg.value = resp.message || "Não foi possível efetuar o cadastro ";
          return;
        }
        ok.value = true;
      } catch (e) {
        msgKey.value++;
        msg.value = "Não foi possível efetuar o cadastro";
      } finally {
        loading.value = false;
      }
    }

    const loadingCep = ref(false);
    const encontrouCep = ref(false);

    return {
      estadoCivilOptions,
      ufs,
      tiposPessoa,
      user,
      cadastrar,
      msg,
      msgKey,
      loading,
      ok,
      async acessar() {
        await logout();
      },

      loadingCep,
      encontrouCep,
      async consultarCep() {
        encontrouCep.value = false;
        if (!user.cep) {
          return;
        }
        loadingCep.value = true;
        try {
          const resp = await consultarCep(user.cep);
          if (!resp.erro) {
            user.logradouro = resp.logradouro;
            user.complemento = resp.complemento;
            user.bairro = resp.bairro;
            user.cidade = resp.localidade;
            user.uf = resp.uf;
            encontrouCep.value = true;
          }
        } catch (e) {
          console.log("Falha ao buscar cep", user.cep);
        } finally {
          loadingCep.value = false;
        }
      },
    };
  },
  computed: {
    isPF(): boolean {
      return this.user.tipo !== "J";
    },
    labelDocumento(): string {
      return this.isPF ? "CPF" : "CNPJ";
    },
    labelRgInsc(): string {
      return this.isPF ? "RG" : "Insc. Estadual";
    },
  },
  watch: {
    "user.telefone"() {
      if (this.isWhats) {
        this.user.whatsapp = this.user.telefone;
      }
      if (this.isTelegram) {
        this.user.telegram = this.user.telefone;
      }
    },
    isWhats(v) {
      v && (this.user.whatsapp = this.user.telefone);
    },
    isTelegram(v) {
      v && (this.user.telegram = this.user.telefone);
    },
  },
});
</script>

<style scoped>
/* ::v-deep(.p-field) {
  margin-bottom: 0.5em;
}
::v-deep(.p-field label) {
  margin-bottom: 0.2em;
} */
</style>