
import { defineComponent, onMounted, ref } from "vue";
import Formulario from "./Formulario.vue";
import { http } from "@/api/rest";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Cadastro",
  components: {
    Formulario,
  },
  data() {
    return {
      checkPatrocinador: false,
    };
  },
  setup() {
    const router = useRouter();
    const confirmou = ref(false);
    const patrocinador = ref();
    const loading = ref(false);
    async function loadInfo() {
      loading.value = true;
      try {
        const result = (
          await http.get(
            `/api/cadastro/${router.currentRoute.value.params.hash}`
          )
        ).data;
        patrocinador.value = result;
      } finally {
        loading.value = false;
      }
    }
    onMounted(() => {
      if (["298bb8bc", "378bc4uw"].includes(router.currentRoute.value.params.hash as string)) {
        confirmou.value = true;
      } else {
        loadInfo();
      }
    });
    return {
      confirmou,
      loading,
      patrocinador,
    };
  },
});
